import { CanvasAnimationAtom } from "./atoms";

export interface CanvasAnimation {
	beforeDraw?({
		frameCount,
		ctx,
	}: {
		frameCount: number;
		ctx: CanvasRenderingContext2D;
	}): void;
}

interface ICanvasAnimationOptions {
	bgColor: string;
}

export abstract class CanvasAnimation {
	options: ICanvasAnimationOptions;
	atoms: CanvasAnimationAtom[];
	speed: number = 1;

	static defaultOptions: ICanvasAnimationOptions = { bgColor: "#000000" };

	constructor() {
		this.atoms = [];
		this.options = CanvasAnimation.defaultOptions;
	}

	configure(options: ICanvasAnimationOptions) {
		this.options = options;
	}

	registerAtom(atom: CanvasAnimationAtom) {
		if (!this.atoms.includes(atom)) {
			this.atoms.push(atom);
		}
	}

	draw({
		frameCount: _frameCount,
		ctx,
	}: {
		frameCount: number;
		ctx: CanvasRenderingContext2D;
	}) {
		const frameCount = _frameCount * this.speed;
		ctx.save();
		const drawArgs = { frameCount, ctx };
		this.beforeDraw?.(drawArgs);
		ctx.restore();
		if (this.atoms) {
			for (const atom of this.atoms) {
				ctx.save();
				atom.update?.(drawArgs);
				ctx.restore();
			}
			const _atoms = [...this.atoms].sort(
				(a, b) =>
					(b.zIndex?.(drawArgs) ?? 0) - (a?.zIndex?.(drawArgs) ?? 0)
			);
			for (const atom of _atoms) {
				ctx.save();
				atom.draw(drawArgs);
				ctx.restore();
			}
		}
	}
}
