import { CanvasAnimation } from "..";

export interface CanvasAnimationAtom {
	update?({
		frameCount,
		ctx,
	}: {
		frameCount: number;
		ctx: CanvasRenderingContext2D;
	}): void;
	zIndex?({
		frameCount,
		ctx,
	}: {
		frameCount: number;
		ctx: CanvasRenderingContext2D;
	}): number;
}

export abstract class CanvasAnimationAtom {
	constructor(animation: CanvasAnimation) {
		animation.registerAtom(this);
	}
	abstract draw({
		frameCount,
		ctx,
	}: {
		frameCount: number;
		ctx: CanvasRenderingContext2D;
	}): void;
}
