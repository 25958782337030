import { CSSProperties } from "react";

interface IProps {
	uri: string;
	theme?: 0 | 1;
	style?: CSSProperties;
}

export const EmbeddedSpotifyPlayer = ({ theme = 0, uri, style }: IProps) => {
	const type = uri.split(":")[1];
	const id = uri.split(":")[2];
	const src = `https://open.spotify.com/embed/${type}/${id}?utm_source=generator&theme=${theme}`;
	return (
		<iframe
			title={src}
			style={{
				borderRadius: 12,
				width: "100%",
				height: "100%",
				...style,
			}}
			allowFullScreen
			allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
			src={src}
		/>
	);
};
