import { createGlobalStyle, ThemeProvider } from "styled-components";

import { styleReset } from "react95";
// pick a theme of your choice
import original from "react95/dist/themes/modernDark";
import { Canvas } from "./components/canvas";
import { CrocsAnimation } from "./animations/crocsAnimation";
import { EmbeddedSpotifyPlayer } from "./components/embeddedSpotifyPlayer";
import { Music } from "./pages/music";
import { CirclesAnimation } from "./animations/circlesAnimation";

const animation = new CirclesAnimation(200);

// original Windows95 font (optionally)

const ms_sans_serif = require("react95/dist/fonts/ms_sans_serif.woff2");
const ms_sans_serif_bold = require("react95/dist/fonts/ms_sans_serif_bold.woff2");

const GlobalStyles = createGlobalStyle`
  ${styleReset}
  @font-face {
    font-family: 'ms_sans_serif';
    src: url('${ms_sans_serif}') format('woff2');
    font-weight: 400;
    font-style: normal
  }
  @font-face {
    font-family: 'ms_sans_serif';
    src: url('${ms_sans_serif_bold}') format('woff2');
    font-weight: bold;
    font-style: normal
  }
  body {
    font-family: 'ms_sans_serif';
  }
`;

const App = () => (
	<div
		style={{
			width: "100%",
			height: "100%",
		}}
	>
		<GlobalStyles />
		<ThemeProvider theme={original}>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
					overflow: "hidden",
					position: "relative",
					height: "100%",
					width: "100%",
				}}
			>
				<div>
					<Music />
				</div>
				<div
					style={{
						position: "absolute",
						top: 0,
						left: 0,
						width: "100%",
						height: "100%",
						zIndex: -1,
					}}
				>
					<Canvas draw={(args) => animation.draw(args)} />
				</div>
			</div>
		</ThemeProvider>
	</div>
);

export default App;
