import { useRef, useState, useEffect } from "react";
import useElementSize from "../hooks/useElementSize";

export type TCanvasAnimation = (drawProps: ICanvasAnimationProps) => void;

interface ICanvasProps {
	draw: TCanvasAnimation;
}

interface ICanvasAnimationProps {
	frameCount: number;
	ctx: CanvasRenderingContext2D;
}

export const Canvas = ({
	draw,
	...props
}: ICanvasProps & React.CanvasHTMLAttributes<HTMLCanvasElement>) => {
	const canvasRef = useRef<HTMLCanvasElement>(null);

	const [containerRef, { width, height }] = useElementSize();

	const [canvasRenderingContext, setCanvasRenderingContext] =
		useState<CanvasRenderingContext2D | null>(null);

	useEffect(() => {
		let frameCount = 0;
		let animationFrameId: number;
		if (canvasRenderingContext) {
			const render = () => {
				if (canvasRenderingContext) {
					frameCount++;
					draw({ frameCount, ctx: canvasRenderingContext });
				}
				animationFrameId = window.requestAnimationFrame(render);
			};
			render();
		}
		return () => {
			window.cancelAnimationFrame(animationFrameId);
		};
	}, [draw, canvasRenderingContext]);

	useEffect(() => {
		if (canvasRef.current) {
			const canvas = canvasRef.current;
			const _ctx = canvas.getContext("2d");
			if (_ctx) {
				setCanvasRenderingContext(_ctx);
			}
		}
	}, []);

	return (
		<div style={{ width: "100%", height: "100%" }} ref={containerRef}>
			<canvas width={width} height={height} ref={canvasRef} {...props} />
		</div>
	);
};
