import { SpiralAtom } from "./../atoms/spiral";
import { CrocAtom } from "../atoms/croc";
import { CanvasAnimation } from "..";

export class CirclesAnimation extends CanvasAnimation {
	speed = 0.7;
	constructor(N: number) {
		super();
		for (var i = 0; i < N; i++) {
			new SpiralAtom(this);
		}
	}
	beforeDraw({
		frameCount,
		ctx,
	}: {
		frameCount: number;
		ctx: CanvasRenderingContext2D;
	}): void {
		ctx.fillStyle = "#00000033";
		ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
	}
}
