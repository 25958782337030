import React, { CSSProperties } from "react";

interface IProps {
	gap?: number;
	children: React.ReactNode;
}

const flexStyle: CSSProperties = {
	display: "flex",
	height: "100%",
	width: "100%",
};

const FlexRow: React.FC<IProps> = ({ children, gap }) => {
	return (
		<div
			style={{
				...flexStyle,
				flexDirection: "row",
				gap,
			}}
		>
			{children}
		</div>
	);
};
const FlexColumn: React.FC<IProps> = ({ children, gap }) => {
	return (
		<div style={{ ...flexStyle, flexDirection: "column", gap }}>
			{children}
		</div>
	);
};

export const Flex: React.FC<{ children: React.ReactNode }> & {
	Row: typeof FlexRow;
	Column: typeof FlexRow;
} = ({ children }: { children: React.ReactNode }) => {
	return (
		<div
			style={{
				...flexStyle,
				flexDirection: "row",
				flex: 1,
			}}
		>
			{children}
		</div>
	);
};

Flex.Row = FlexRow;
Flex.Column = FlexColumn;
