import { EmbeddedSpotifyPlayer } from "../components/embeddedSpotifyPlayer";
import { Flex } from "../components/flex";

export const Music = () => (
	<Flex.Row gap={36}>
		<EmbeddedSpotifyPlayer
			theme={1}
			style={{ width: 480, height: 480 }}
			uri={"spotify:album:6OKLUOiHDbEC2L3humWisY"}
		/>
		<EmbeddedSpotifyPlayer
			theme={1}
			style={{ width: 480, height: 480 }}
			uri={"spotify:album:6DYkdrJmzIgRDaHslnZeF5"}
		/>
		<EmbeddedSpotifyPlayer
			theme={1}
			style={{ width: 480, height: 480 }}
			uri={"spotify:album:2ZtunF7YTMVu48RDeni9Qf"}
		/>
	</Flex.Row>
);
