import { CanvasAnimationAtom } from ".";
import { CanvasAnimation } from "..";
import { getRandomFloat } from "../../utils/getRandomFloat";

interface IDrawProps {
	frameCount: number;
	ctx: CanvasRenderingContext2D;
}

export class SpiralAtom extends CanvasAnimationAtom {
	x: number;
	y: number;
	rand: number;

	constructor(animation: CanvasAnimation) {
		super(animation);
		this.x = getRandomFloat(0, 1);
		this.y = getRandomFloat(0, 1);
		this.rand = getRandomFloat(-0.9, 1.1);
	}
	calculateXY({ ctx, frameCount }: IDrawProps) {
		const sine = Math.sin((this.rand * frameCount) / 10);
		const cosine = Math.cos((this.rand * frameCount) / 10);
		const S = 300 * Math.sin(frameCount / 100) * this.rand;

		const s = S * sine;
		const c = S * cosine;
		const x =
			this.rand * 20 * Math.sin((this.rand * frameCount) / 10) +
			this.x * ctx.canvas.width +
			s;
		const y =
			this.rand * 20 * Math.cos((this.rand * frameCount) / 10) +
			this.y * ctx.canvas.height +
			c;
		return [x, y];
	}
	draw({ frameCount, ctx }: IDrawProps) {
		const [x1, y1] = this.calculateXY({ frameCount, ctx });
		const [x2, y2] = this.calculateXY({ frameCount: frameCount + 1, ctx });
		ctx.beginPath();
		ctx.moveTo(x1, y1);
		ctx.lineTo(x2, y2);
		ctx.strokeStyle = "#ffffff";
		ctx.stroke();
	}
}
